import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarCheck,
  faExchangeAlt,
  faFileExcel,
  faFileInvoiceDollar,
  faFileMedical,
  faLeaf,
  faMoneyCheckDollar,
  faUserPen,
} from '@fortawesome/free-solid-svg-icons';
import { AnyTask, AnyTaskType } from '../../contexts/remote-data/useSiteTasks';

export interface StatusNameDefinition {
  status: AnyTask['status'];
  statusName: string;
}

export interface TaskTypeDefinition {
  type: AnyTaskType;
  typeName: string;
  icon: IconProp;
  status?: StatusNameDefinition[];
  defaultNotes: string;
  description: string;
}

export const generic_status_definition: StatusNameDefinition[] = [
  { status: 'todo', statusName: 'To do' },
  { status: 'in_progress', statusName: 'In progress' },
  { status: 'review', statusName: 'Review' },
  { status: 'done', statusName: 'Done' },
  { status: 'cancelled', statusName: 'Cancelled' },
];

export const getTaskStatusName = (status: AnyTask['status']) => {
  return generic_status_definition.find((item) => item.status === status)?.statusName || 'Unknown';
};

// Convert backend tasks to frontend names
// Define the ordering of status
export const task_types_definitions: TaskTypeDefinition[] = [
  {
    type: 'assess_ets_eligibility',
    typeName: 'Assess ETS eligibility',
    description: 'Identify qualifying forest and forecast ETS returns',
    icon: faLeaf,
    defaultNotes: `Under the map page:
- There should be polygons created for all existing forest areas
- Each polygon has at least a species and an establishment date
Once complete, each forest area should have a category and be identified as eligible or ineligible forest.
`,
  },
  {
    type: 'transfer_nzus',
    typeName: 'Transfer NZUs',
    description: 'Request the transfer of NZUs from a holding account',
    icon: faMoneyCheckDollar,
    defaultNotes: `Retrieve the customer and target holding account number from the landholder and perform the transfer in their NZ-ETR account.`,
  },
  {
    type: 'create_ets_account',
    typeName: 'Create an ETS account',
    description: 'Open accounts with NZ-ETR and MPI before registering land in the ETS',
    icon: faUserPen,
    defaultNotes: `
- Go to NZ-ETR (https://emissionsregister.govt.nz/Authentication/Logon.aspx) to create a Holding account, here is the link to their user guide (https://www.epa.govt.nz/assets/Uploads/Documents/Emissions-Trading-Scheme/Guidance/How-to-Open-an-Account-for-a-new-Account-Holder-in-the-Emissions-Trading-Scheme-July-2019.pdf). Once complete, you should have an NZ-number. Save it under the Overview page.
- Go to Tupu-ake (https://www.mpi.govt.nz/forestry/forestry-in-the-emissions-trading-scheme/ets-online-system/) and follow their user guide (https://www.mpi.govt.nz/dmsdocument/13495-Emissions-Trading-Scheme-ETS-system-user-guide) to create an account. You can also fill in the AAR form to appoint a representative
`,
  },
  {
    type: 'transfer_ets_participation',
    typeName: 'Transfer ETS participation',
    description: 'Notify MPI of a change in ownership',
    icon: faExchangeAlt,
    defaultNotes: `Fill in the appropriate Transfer of Participation form, submit it to MPI and pay the associated fee.`,
  },
  {
    type: 'register_land_to_ets',
    typeName: 'Register land in the ETS',
    description: 'Confirm areas, collate evidence and submit an ETS application',
    icon: faFileMedical,
    defaultNotes: `Polygons to include in the application should have all their attributes filled in and a CAA number. Make sure to confirm the final mapping with the ETS Account Holders.

Prepare and organise your evidence. You can use FieldScan to collect photos.

Once you’re ready to submit the ETS application, download the ETS Shapefile from the map page. Login to Tupu-ake and start a service request to register land in the ETS.

This task is complete once the payment has been made and the application status in Tupu-ake is “Submitted”.`,
  },
  {
    type: 'remove_land_from_ets',
    typeName: 'Remove land from the ETS',
    description: 'Apply to MPI to remove an area of ETS-registered forest',
    icon: faFileExcel,
    defaultNotes: 'Map the area to remove, download a Shapefile and upload it in Tupu-ake.',
  },
  {
    type: 'per',
    typeName: 'PER',
    description: '',
    icon: faCalendarCheck,
    status: [
      { status: 'no_per', statusName: 'No PER' },
      { status: 'prepare', statusName: 'Prepare' },
      { status: 'landholder_input', statusName: 'Landholder Input' },
      { status: 'process_changes', statusName: 'Processing Changes' },
      { status: 'submit', statusName: 'Submit PER' },
      { status: 'finish', statusName: 'Complete' },
    ],
    defaultNotes: '',
  },
  {
    type: 'invoice',
    typeName: 'Invoice',
    description: '',
    icon: faFileInvoiceDollar,
    status: [
      { status: 'prepare_invoice', statusName: 'Prepare Invoice' },
      { status: 'awaiting_units', statusName: 'Awaiting Units' },
      { status: 'invoice_sent', statusName: 'Invoice Sent' },
      { status: 'in_dispute', statusName: 'In Dispute' },
      { status: 'transaction_prepared', statusName: 'Transaction Prepared' },
      { status: 'complete', statusName: 'Complete' },
    ],
    defaultNotes: '',
  },
];

type taskTypesMap = { [key in AnyTaskType]: TaskTypeDefinition };
export const taskDefinitionsByType: taskTypesMap = task_types_definitions.reduce((obj, item) => {
  return {
    ...obj,
    [item.type]: item,
  };
}, {} as taskTypesMap);

export const getTaskTypeName = (type: AnyTaskType) => {
  return task_types_definitions.find((item) => item.type === type)?.typeName || 'Unknown';
};

export interface EmailStatusDefinition {
  status: string;
  statusName: string;
  bg: string;
}

export const email_status_name_list: EmailStatusDefinition[] = [
  { status: 'email_sending', statusName: 'Sending Email', bg: 'warning' },
  { status: 'email_sent', statusName: 'Email Sent', bg: 'success' },
  { status: 'email_failed', statusName: 'Email Failed', bg: 'danger' },
];
