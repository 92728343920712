import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';

import { faLandmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useApiService } from '../apis/api-service';
import { AuthFeature, InvoiceTaskProperties } from '../apis/generated';
import { Circled } from '../components/Circled';
import { UpdaterFunction } from '../contexts/remote-data/sources/Store';
import { AnyTask } from '../contexts/remote-data/useSiteTasks';
import {
  email_status_name_list,
  generic_status_definition,
  taskDefinitionsByType,
} from '../customer/overview/TasksDefinition';
import { EditAssigneeDropdown } from './EditAssigneeDropdown';
import styles from './TaskEntry.module.scss';
import { useIsSite } from '../contexts/remote-data/useIsSite';
import { useIsSiteEditor } from '../companies/useIsSiteEditor';

export interface TaskEntryProps {
  task: AnyTask;
  setTask?: (updater: UpdaterFunction<AnyTask>) => void;
  showAssignee?: boolean;
}

const TaskEntry: React.FC<TaskEntryProps> = ({ task, setTask, showAssignee }) => {
  const typeDefinition = useMemo(() => {
    return taskDefinitionsByType[task.task_type];
  }, [task.task_type]);

  const [hasInvoiceAccess, setHasInvoiceAccess] = useState<boolean | undefined>(undefined);
  const apiService = useApiService();
  useEffect(() => {
    if (hasInvoiceAccess === undefined) {
      void (async () => {
        const access = await apiService.getCustomerAccess(task.customer_id);
        setHasInvoiceAccess(
          access.available_features.some((feature) => AuthFeature.CC_INVOICE_MANAGER === feature),
        );
      })();
    }
  }, [apiService, hasInvoiceAccess, task.customer_id]);

  const getStatusName = (status: string): string | undefined => {
    if (typeDefinition.status === undefined) {
      return generic_status_definition.find((val) => val.status === status)?.statusName;
    }
    return typeDefinition.status.find((st) => st.status === status)?.statusName;
  };

  const navigate = useNavigate();

  const getEmailStatusName = (properties: InvoiceTaskProperties): string | undefined => {
    return email_status_name_list.find((item) => item.status === properties.email_sending_status)
      ?.statusName;
  };
  const getEmailStatusBg = (properties: InvoiceTaskProperties): string | undefined => {
    return email_status_name_list.find((item) => item.status === properties.email_sending_status)
      ?.bg;
  };

  const [hovered, setHovered] = useState(false);

  const isSiteEditor = useIsSiteEditor();

  if (showAssignee && !setTask) {
    throw new Error('setTask is required when showAssignee is true');
  }

  const onClick = () => {
    if (task.task_type !== 'invoice' || hasInvoiceAccess) {
      navigate(`/customer/${task.customer_id}/tasks/${task.uuid}`);
    }
  };

  const isSite = useIsSite();

  return (
    <Row
      className={`p-0 m-0 bg-hover-grey-95 cursor-pointer ${styles.task_row}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Col md={1} className="py-3" onClick={onClick}>
        <Circled className="" size="2.5em">
          <FontAwesomeIcon icon={typeDefinition?.icon ?? faLandmark} />
        </Circled>
      </Col>
      {!isSite && (
        <Col className="py-3 d-flex align-items-center" md={4} onClick={onClick}>
          {task.site_name ? task.site_name : `CCP${task.customer_id}`}
        </Col>
      )}
      <Col className="py-3  d-flex align-items-center" md={isSite ? 4 : 3} onClick={onClick}>
        {typeDefinition?.typeName || 'Unknown'}
      </Col>
      <Col className="py-3  d-flex align-items-center" md={isSite ? 5 : 2} onClick={onClick}>
        {getStatusName(task.status) || 'Unknown'}
        {task.task_type === 'invoice' && task.properties !== undefined && (
          <Badge className="ms-3" bg={getEmailStatusBg(task.properties as InvoiceTaskProperties)}>
            {getEmailStatusName(task.properties as InvoiceTaskProperties)}
          </Badge>
        )}
      </Col>
      {showAssignee && setTask && (!isSite || isSiteEditor) && (
        <Col className="py-3 d-flex align-items-center gap-2" md={2}>
          <EditAssigneeDropdown
            assignee={task.assignee}
            setAssignee={(assignee: number | undefined) => {
              setTask((task) => ({ ...task, assignee: assignee }));
            }}
            visibleEditButton={hovered}
          />
        </Col>
      )}
    </Row>
  );
};

export default TaskEntry;
